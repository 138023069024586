import $ from "cash-dom";
import VanillaModal from 'vanilla-modal';

var modalContainer = document.createElement("div");
modalContainer.className = "modal";
modalContainer.setAttribute("role", "dialog");
modalContainer.setAttribute("aria-label", "Dialog Window (Press escape to close)");
// modalContainer.setAttribute("tabindex", "-1");
modalContainer.innerHTML = "\
  <div class=\"modal-inner\" role=\"document\">\
    <span data-modal-close class=\"icon-close\"></span>\
    <div class=\"modal-content\"></div>\
  </div>";
document.getElementsByTagName("main")[0].appendChild(modalContainer);


const modal = new VanillaModal({
  clickOutside: true,
  onOpen: function() {
    document.getElementsByClassName("modal")[0].setAttribute("tabindex","-1");
    document.getElementsByClassName("modal")[0].focus();
  }
});
