import $ from "cash-dom";
import Rellax from "rellax";
import * as _modal from "./rigby/modal.js";
import * as _ellipsis from "ellipsis.js";
import AOS from 'aos';
import StickyFill from 'stickyfilljs';

var marqueeHeight = 0;

if ($(".marquee")) {
  marqueeHeight = $(".marquee").height() + 200;
}

AOS.init();



window.setTimeout(function(){
  Ellipsis({
    ellipsis: '…', //default ellipsis value
    debounce: 0, //if you want to chill out your memory usage on resizing
    responsive: true, //if you want the ellipsis to move with the window resizing
    className: '.clamp', //default class to apply the ellipsis
    lines: 5 //default number of lines when the ellipsis will appear
  });
}, 500);

$("body").on("click", `button.button--menu`, function(event) {
  const $button = $(this);
  //const targetId = $button.attr("menu");
  const $target = $(`header .menu--primary`);
  const open = $target.hasClass("active");

  $button.attr("aria-expanded", !open);
  $target.toggleClass("active", !open);
});

$("body").on("click", `a[href^="mailto"]`, function(event) {
  if (typeof(ga) === typeof(Function)) {
    ga('send', 'event', 'Email', 'Start', 'Contact Us');
  }
});

$(".card, .person").each(function(){
  var card = $(this);
  var link = card.find("a");

  if (link.length > 0) {
    card.addClass("hoverable");

    if (!$("body").hasClass("samadmin")) {
      card.on("click", function(event){
        link[0].click();
      })
    }
  }
})

if (window["tinymce"]) {
  tinymce.on("AddEditor", function addEditor(e) {
    var editor = $("#" + e.editor.id);
    editor.closest("section.marquee").addClass("editing");
  });
  tinymce.on("RemoveEditor", function removeEditor(e) {
    var editor = $("#" + e.editor.id);
    editor.closest("section.marquee").removeClass("editing");
  });
}

function throttle(fn, wait) {
  var time = Date.now();
  return function() {
    if ((time + wait - Date.now()) < 0) {
      fn();
      time = Date.now();
    }
  }
}

function callback() {
  if (window.scrollY > marqueeHeight) {
    $(".marquee").removeClass("sticky");
  } else {
    $(".marquee").addClass("sticky");
  }
}

window.addEventListener('scroll', throttle(callback, 600));


StickyFill.add($(".sticky"));



// var rellax = new Rellax('.rellax',{
//   center: true,
//   speed: 1
// });

// var test = new Rellax('.page-container',{
//   speed: 1,
//   center: true,
// });


// $('h2').text(function (i, html) {
//   return text.replace(/o/g, '<span class="rellax">$1</span>');
// });

// var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
// var midpoint = h/2;
// let moving = $(".rellax");

// let last_known_scroll_position = 0;
// let ticking = false;



// function doSomething(scroll_pos) {

//   moving.each(function(){
//     var viewportOffset = this.getBoundingClientRect();
//     var top = viewportOffset.top;
//     var computed = (midpoint - top)*0.02;
//     $(this).css("transform", "translate3d(0px, "+computed+"px, 0px)");
//   });
// }

// window.addEventListener('scroll', function(e) {
//   last_known_scroll_position = window.scrollY;

//   if (!ticking) {
//     window.requestAnimationFrame(function() {
//       doSomething(last_known_scroll_position);
//       ticking = false;
//     });

//     ticking = true;
//   }
// });